@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define styles for the date filter container */
.date-filter-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  justify-content: start;
}



/* Define styles for the date input fields */
.date-input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

/* Define media queries for mobile devices */
@media (max-width: 767px) {
  .date-picker-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 5px;
  }

  .date-input {
    width: 100%;
    height: 40px;
    padding: 5px;
    background-color: white;
  }
}

.transparent-marker {
  opacity: 0.5; /* Adjust the opacity as needed */
}
/* Neon text with brick wall background */
.message-display-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; /* Ensures the background image covers the whole screen */
  background-blur: 5px; /* Optional: You can add blur to the background for better readability */
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* Typing animation for the text */
@keyframes typing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.98;
  }
}

/* Neon text styles with a futuristic font */
.neon-text {
  color: #ffd700; /* Neon golden color */
  font-family: 'Orbitron', sans-serif; /* Futuristic font */
  font-weight: bold;
  font-size: 100px; /* Large font size for impact */
  text-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700, 0 0 40px #ffd700;
  animation: typing 4s steps(40) 1s infinite, neonGlow 1.5s ease-in-out infinite;
  z-index: 100;
  display: inline-block;
}

/* Neon text glowing animation */
@keyframes neonGlow {
  0% {
    text-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700;
  }
  50% {
    text-shadow: 0 0 15px #ffd700, 0 0 25px #ffd700;
  }
  100% {
    text-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700;
  }
}

/* Confetti fall animation */
@keyframes confettiFall {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

/* custom-date-range.css */

/* Override the overall container */
.rdrCalendarWrapper {
  background-color: #1f2937 !important; /* Dark slate (Tailwind's gray-800) */
  border: 1px solid #374151 !important; /* Tailwind's gray-700 */
  border-radius: 0.5rem !important; /* rounded */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5) !important;
  color: #e5e7eb !important;
}

/* Month Header */
.rdrMonthPicker,
.rdrMonthAndYearWrapper {
  color: #e5e7eb !important; /* light gray */
}

.rdrMonthPicker select > option {
  color: #e5e7eb !important; /* light gray */
}

/* Weekday names */
.rdrWeekDay {
  color: #9ca3af !important; /* Tailwind's gray-400 */
}

/* Individual day cells */
.rdrDay {
  color: #d1d5db !important; /* light gray */
  background-color: transparent !important;
  border: none !important;
  transition: background-color 0.3s ease !important;
  color: #e5e7eb !important;
}

.rdrDay:hover {
  background-color: #374151 !important; /* hover dark gray */
  border-radius: 0.375rem !important;
  color: #e5e7eb !important;
}

.rdrDayNumber {
  color: white !important;
}

.rdrDayNumber span {
  color: white !important;
}

/* Selected day styling */
.rdrDaySelected,
.rdrDayStartPreview,
.rdrDayEndPreview {
  background-color: #2563eb !important; /* blue-600 */
  color: white !important;
  border-radius: 0.375rem !important;
}

/* Today styling */
.rdrDayToday {
  border: 1px solid #2563eb !important;
  color: #e5e7eb !important;
}

/* Input fields in the DateRangePicker (if any) */
.rdrInput {
  background-color: #374151 !important; /* dark gray */
  color: #e5e7eb !important;
  border: 1px solid #4b5563 !important; /* gray-600 */
  border-radius: 0.375rem;
}

.rdrDateDisplayWrapper {
  background-color: #1f2937 !important; /* dark gray */
  color: #e5e7eb !important;
}

.rdrDefinedRangesWrapper {
  background-color: #1f2937 !important; /* dark gray */
  color: #e5e7eb !important;
}

.rdrStaticRange {
  background-color: #1f2937 !important; /* dark gray */
}

.rdrDateRangePickerWrapper {
  background-color: transparent;
}

.rdrDefinedRangesWrapper{
  display: none;
}